const initialState = {
  success: false,
  error: false,
};

const forgotpassword = function (state = initialState, action) {
  switch (action.type) {
    case "FORGOTPASSWORD_SUCCESS": {
      return {
        ...initialState,
        success: action.payload,
      };
    }
    case "FORGOTPASSWORD_ERROR": {
      return {
        success: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default forgotpassword;
